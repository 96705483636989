<ui-modal modalTitle="Media Library" size="lg">

	<button *ngFor="let tab of tabs" class="btn border-0 mr-2" [ngClass]="{ 'btn-outline-secondary': selectedTab !== tab.id, 'btn-primary': selectedTab === tab.id }" (click)="selectTab(tab.id)" ui-modal-header>{{tab.description}}</button>

	<ng-container *ngIf="selectedTab === 'upload'">
		<p *ngIf="type === 'asset'" class="alert alert-info">
			<i class="wq wq-info mr-2"></i>Uploaded images will be saved in your <b>Asset Library</b>.
		</p>

		<p *ngIf="type === 'snagg'" class="alert alert-info">
			<i class="wq wq-info mr-2"></i>Uploaded images will be saved under <b>Snagg Library</b>.
		</p>

		<p *ngIf="type === 'product'" class="alert alert-info">
			<i class="wq wq-info mr-2"></i>Uploaded images will be saved under <b>Product Images</b>. Images will be downscaled to a maximum size of 512 x 512 pixels.
		</p>

		<div class="image-drop scrollable d-flex flex-wrap p-2" [ngClass]="{ 'border-silver': !draggedOver, 'border-primary': draggedOver }" style="height: 540px;" (dragover)="fileDragOver($event)" (dragleave)="draggedOver = false" (drop)="fileDrop($event)">
			<p *ngIf="!newList.length" class="text-center text-secondary p-4 flex-weight-1">Drag images here to upload.</p>

			<div *ngFor="let item of newList" class="m-1 p-2 border border-light rounded shadow-sm d-flex flex-column selectable hoverable" style="width: 9.375rem; height: 15.5rem;" (click)="selectImage(item)">
				<div *ngIf="item.thumb_url" class="flex-weight-1 product-image" style="width: 100%; height: auto;" [style.backgroundImage]="'url(\'' + item.thumb_url + '\')' | safeStyle"></div>
				<div class="mt-2 text-bold" style="word-wrap: break-word;">{{item.name}}</div>
				<div class="mt-2 text-small text-secondary">{{item.datetime | mySQLDateToISO | localizedDate:'shortDate'}} {{item.datetime | mySQLDateToISO | localizedDate:'shortTime'}}</div>
			</div>
		</div>

		<form>
			<div class="d-flex mt-3 mb-3 align-items-center">
				<label class="col-form-label">Image URL</label>
				<input type="text" class="form-control flex-weight-1 ml-2" [(ngModel)]="url" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				<button type="submit" class="btn btn-success ml-2" [disabled]="disabled || !url" (click)="addImageURL()"><i class="wq wq-add mr-2"></i>Add from URL</button>
				<button type="button" class="btn btn-primary ml-2" [disabled]="disabled" (click)="browseFile()"><i class="wq wq-upload mr-2"></i>Browse</button>
			</div>
		</form>
	</ng-container>

	<ng-container *ngIf="selectedTab === 'product' || selectedTab === 'asset' || selectedTab === 'snagg'">
		<ng-container *ngIf="!showCheckBox; else multiSelectTemplate">
			<div class="input-group mb-2">
				<div class="input-group-prepend"><span class="input-group-text"><i class="wq wq-search"></i></span></div>
				<input type="search" class="form-control" placeholder="Search" [(ngModel)]="search" autocomplete="chrome-off" spellcheck="false" appDesktopFocus>
			</div>

			<div class="mb-2 scrollable d-flex flex-wrap p-2" style="height: 540px;">
				<div *ngFor="let item of list | keywords:search:['name', 'url'] | pagination:pagination" class="m-1 p-2 border border-light rounded shadow-sm d-flex flex-column selectable hoverable" style="width: 9.375rem; height: 15.5rem;" (click)="selectImage(item)">
					<div *ngIf="item.thumb_url" class="flex-weight-1 product-image" style="width: 100%; height: auto;" [style.backgroundImage]="'url(\'' + item.thumb_url + '\')' | safeStyle"></div>
					<div class="mt-2 text-bold" style="word-wrap: break-word;">{{item.name}}</div>
					<div class="mt-2 text-small text-secondary">{{item.datetime | mySQLDateToISO | localizedDate:'shortDate'}} {{item.datetime | mySQLDateToISO | localizedDate:'shortTime'}}</div>
				</div>

				<p *ngIf="!pagination.count" class="text-secondary">No items found.</p>
			</div>
			<app-pagination [pagination]="pagination"></app-pagination>
		</ng-container>

		<ng-template #multiSelectTemplate>
			<div class="header-left-buttons mb-4" style="text-align: right;" *ngIf="multiSelectList.length > 0">
				<div>
					<strong class="selected-item-font mr-2">{{ multiSelectList.length }} ITEMS SELECTED</strong>
					<button class="btn btn-medium mr-2 nowrap header-button-padding" (click)="clearAllImages()"><i class="wq wq-checkbox-unchecked mr-2"></i> Clear</button>
					<button class="btn btn-primary mr-2 nowrap header-button-padding" (click)="selectAllImages()">Select All</button>
					<button (click)="archiveImage()" class="btn btn-secondary mr-2 nowrap header-button-padding"><i class="wq wq-delete mr-2"></i> Archive</button>
				</div>
			</div>
			<div class="input-group mb-2">
				<div class="input-group-prepend"><span class="input-group-text"><i class="wq wq-search"></i></span></div>
				<input type="search" class="form-control" placeholder="Search" [(ngModel)]="search" autocomplete="chrome-off" spellcheck="false" appDesktopFocus>
			</div>

			<div class="mb-2 scrollable d-flex flex-wrap p-2" style="height: 540px;">
				<div *ngFor="let item of list | keywords:search:['name', 'url'] | itemlist:filteredItems:'list' | pagination:pagination" class="border m-1 p-2 rounded shadow-sm d-flex flex-column selectable hoverable" style="width: 9.375rem; height: 15.5rem;" [ngClass]="{ 'border-light': !isImageSelected(item), 'border-primary bg-primary-15': isImageSelected(item) }">
					<label class="custom-control custom-checkbox mb-0">
						<input type="checkbox" class="custom-control-input" [ngModel]="isImageSelected(item)" (ngModelChange)="toggleImage(item)">
						<span class="custom-control-label"></span>
					</label>
					<div *ngIf="item.thumb_url" class="flex-weight-1 product-image" style="width: 100%; height: auto;" [style.backgroundImage]="'url(\'' + item.thumb_url + '\')' | safeStyle" (click)="selectImage(item)"></div>
					<div class="mt-2 text-bold" style="word-wrap: break-word;" (click)="selectImage(item)">{{item.name}}</div>
					<div class="mt-2 text-small text-secondary" (click)="selectImage(item)">{{item.datetime | mySQLDateToISO | localizedDate:'shortDate'}} {{item.datetime | mySQLDateToISO | localizedDate:'shortTime'}}</div>
				</div>

				<p *ngIf="!pagination.count" class="text-secondary">No items found.</p>
			</div>
			<app-pagination [pagination]="pagination"></app-pagination>
		</ng-template>

	</ng-container>

	<input type="file" class="d-none" (change)="uploadImage()" #fileInput>

	<button class="btn btn-secondary" (click)="modal.close()" ui-modal-footer>Cancel</button>
</ui-modal>
