<ui-modal [modalTitle]="title" size="lg">

	<div class="input-group mb-2">
		<span class="input-group-prepend"><span class="input-group-text"><i class="wq wq-search"></i></span></span>
		<input type="search" class="form-control" placeholder="Search" [(ngModel)]="search" autocomplete="chrome-off" spellcheck="false" appDesktopFocus>
	</div>

	<div style="height: 600px;" class="scrollable mb-2">
		<table class="table table-hover">
			<thead>
				<tr>
					<th>Name</th>
					<th class="text-center"></th>
					<th>Location</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let item of list | keywords:search:['id', 'name', 'posttown', 'postcode'] | pagination:pagination" class="selectable" (click)="selectItem(item)">
					<td>
						<b>{{item.name}}</b>
					</td>
					<td class="text-center">
						<span *ngIf="item.for_organisation && !item.from_catalogue" class="badge badge-info ml-2">This Organisation</span>
						<span *ngIf="item.from_catalogue" class="badge badge-info ml-2">From Catalogue</span>
					</td>
					<td>{{item.posttown}} <small>{{item.postcode}}</small></td>
				</tr>
				<tr *ngIf="!pagination.count">
					<td colspan="2" class="text-secondary">No entities found.</td>
				</tr>
			</tbody>
		</table>
	</div>

	<app-pagination [pagination]="pagination"></app-pagination>

	<button class="btn btn-secondary" (click)="modal.close()" ui-modal-footer>Cancel</button>

</ui-modal>
