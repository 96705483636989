<ui-modal modalTitle="Advanced Search" size="xxl" [footer]="false">
	<div class="d-flex flex-row mt-n4">
		<div class="d-flex flex-column">
			<app-filter (filterChanged)="applyFilters($event)" [filterData]="backupSearchResults"></app-filter>
		</div>
		<div class="d-flex flex-column flex-weight-1">
			<div class="d-flex flex-row border-bottom">
				<div class="d-flex flex-column flex-weight-1">
					<div class="d-flex">
						<div *ngFor="let t of tabList" class="px-3 py-4 flex-weight-1 hoverable d-flex align-items-center" [ngClass]="{ 'border-primary border-4 text-bold border-bottom': activeTab === t.id, 'border-lightgrey': activeTab !== t.id , 'selectable': !t.disabled}" (click)="!t.disabled && (activeTab = t.id)">
							<span class="text-uppercase text-small text-bold mr-2" [ngClass]="{ 'text-secondary': activeTab !== t.id }"><i class="mr-2" [ngClass]="t.icon"></i>{{t.title}}</span>
						</div>
					</div>
				</div>
				<div class="d-flex flex-column">
					<div class="d-flex">
						<div class="input-group p-3">
							<div class="input-group-prepend">
								<span class="input-group-text"><i class="wq wq-search"></i></span>
							</div>
							<input *ngIf="!!modal?.isOpen" type="search" class="form-control" placeholder="Search" [(ngModel)]="searchQuery" (input)="onSearchInput()" autocomplete="chrome-off" spellcheck="false" (focus)="showRecentSearch()" appDesktopFocus>
							<!-- <div class="recent-search-overlay mt-2" *ngIf="recentSearches.length > 0 && searchQuery.length === 0 && showRecentSearches">
								<table class="table table-hover m-0">
									<tbody>
										<tr *ngFor="let search of recentSearches" class="selectable" (click)="recentSearch(search)">
											<td>
												<div class="text-large text-secondary">{{search}}</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div> -->
						</div>
					</div>
				</div>
			</div>
			<ui-card widget [footer]="true" style="height: 600px;" [wait]="searchResults" [headerDivider]="false">
				<ng-container *ngIf="searchResults">
					<table class="table table-hover global-search-results mt-n4">
						<thead>
							<tr>
								<th class="sticky text-dark"></th>
								<th class="sticky text-dark">Image</th>
								<th class="sticky text-dark">Product</th>
								<th class="sticky text-dark" colspan="1.5">Distributor</th>
								<th class="sticky text-dark">Price</th>
								<th class="sticky text-dark">Stock</th>
								<th class="sticky text-dark shrink nowrap"></th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let item of searchResults | pagination:pagination" [ngClass]="{ 'table-danger': item.discontinued }">
								<td class="h2">
									<i *ngIf="item.supplier_stock.length > 1" class="wq selectable" (click)="systemService.toggleSystemExpanded(item.ean)" [ngClass]="{'wq-caret-right': !systemService.isSystemExpanded(item.ean), 'wq-caret-down': systemService.isSystemExpanded(item.ean)}"></i>
								</td>
								<td class="shrink pr-0">
									<div *ngIf="item.image_url" class="product-image mr-3" [style.backgroundImage]="'url(\'' + item.image_url + '\')' | safeStyle"></div>
								</td>
								<td class="pl-0" style="max-width: 31rem;">
									<div *ngIf="item.short_description" class="text-bold mb-1

									" [innerHTML]="item.short_description | highlight: searchQuery | safeHtml"></div>
									<div *ngIf="item.manufacturer_name || item.model" class="text-secondary mb-1">
										<span [innerHTML]="(item.manufacturer_name | highlight: searchQuery ) + ' ' + (item.manufacturer_name && item.model ? '/' : '') + (item.model | highlight: searchQuery )"></span>
									</div>
									<div *ngIf="item.sku || item.manufacturer_sku" class="text-secondary text-tiny mb-1" [innerHTML]="(item.sku || item.manufacturer_sku) | highlight: searchQuery | safeHtml"></div>
								</td>
								<ng-container *ngIf="!systemService.isSystemExpanded(item.ean); else showExpandedList">
									<td class="text-small d-flex flex-row">
										<ng-container *ngFor="let supplierInfo of item.supplier_stock; let i = index; let isLast = last">
											<span *ngIf="i < 3" class="cat-image border bg-white border-silver rounded-circle mr-2 shadow-sm" [style.backgroundImage]="'url(\'' + supplierInfo.image + '\')' | safeStyle" [ngbTooltip]="supplierInfo.supplier"></span>
										</ng-container>
										<span *ngIf="item.supplier_stock?.length > 3" class="cat-image border bg-white border-silver rounded-circle mr-2 shadow-sm">+{{ item.supplier_stock.length - 3 }}</span>
									</td>
									<td>
										<div *ngIf="item.supplier_stock" class="text-small">{{item.supplier_stock[0]?.sell_price | decimal:2:4:true:item.supplier_stock[0]?.currency_id}}</div>
									</td>
									<td>
										<div *ngIf="item.supplier_stock" class="badge d-block text-tiny px-3" [ngClass]="(item.supplier_stock[0] && item.supplier_stock[0].stock !== 0)? 'badge-success': 'badge-danger'">{{item.supplier_stock[0]?.stock}}</div>
									</td>
									<td class="shrink nowrap">
										<div *ngIf="item.supplier_stock?.length === 1 && canAdd()"><button type="button" class="btn btn-secondary btn-sm add-btn text-tiny mb-1" (click)="addProduct(item, item.supplier_stock[0])">Add</button></div>
									</td>
								</ng-container>
								<ng-template #showExpandedList>
									<td class="text-small">
										<ng-container *ngFor="let supplierInfo of item.supplier_stock; let i = index; let isLast = last">
											<div class="d-flex mb-2">
												{{supplierInfo.supplier}}
											</div>
										</ng-container>
									</td>
									<td class="text-small">
										<ng-container *ngFor="let supplierInfo of item.supplier_stock; let i = index; let isLast = last">
											<div class="d-flex mb-2"> {{supplierInfo.sell_price | decimal:2:4:true:supplierInfo.currency_id}}</div>
										</ng-container>
									</td>
									<td>
										<ng-container *ngFor="let supplierInfo of item.supplier_stock; let i = index; let isLast = last">
											<div class="badge d-block mb-1 text-tiny px-3" [ngClass]="{ 'badge-success': supplierInfo.stock !== 0, 'badge-danger': supplierInfo.stock === 0}">{{supplierInfo.stock}}</div>
										</ng-container>
									</td>
									<td class="shrink nowrap">
										<ng-container *ngIf="canAdd()">
											<ng-container *ngFor="let supplierInfo of item.supplier_stock; let i = index; let isLast = last">
												<div><button type="button" class="btn btn-secondary btn-sm add-btn text-tiny mb-1" (click)="addProduct(item, supplierInfo)">Add</button></div>
											</ng-container>
										</ng-container>
									</td>
								</ng-template>
							</tr>
							<tr *ngIf="!pagination.count">
								<td [attr.colspan]="app.orgInfo?.role.can_see_costs ? 8 : 7" class="text-secondary">No {{activeTab}} found.</td>
								<td *ngIf="app.orgInfo?.role.is_elevated"></td>
							</tr>
						</tbody>
					</table>
				</ng-container>
				<app-pagination [pagination]="pagination" ui-card-footer></app-pagination>
			</ui-card>
		</div>
	</div>
</ui-modal>
