<ui-modal modalTitle="Select Product" size="xl">
	<button *ngFor="let m of modes" class="btn border-0 mr-2" [ngClass]="{ 'btn-primary': mode === m.id, 'btn-outline-secondary': mode !== m.id, 'd-none': !m.show }" (click)="selectMode(m.id)" [appTutorial]="m.tutorial" ui-modal-header><i class="{{m.icon}} mr-2"></i>{{m.description}}</button>
	<button *ngIf="multiSelect" class="btn border-0 mr-2" [ngClass]="{ 'btn-primary': mode === 'selected', 'btn-outline-secondary': mode !== 'selected' }" (click)="selectMode('selected')" ui-modal-header>Selected <ui-button-badge>{{multiSelectList.length}}</ui-button-badge></button>

	<ng-container *ngIf="mode !== 'selected'">
		<div *ngIf="categories.length && app.orgInfo?.role.update_toolkit && !multiSelectProduct" class="form-horizontal">
			<ui-form direction="inline">
				<ui-form-control label="Add to subsystem" class="flex-weight-1">
					<select class="custom-select" [(ngModel)]="selectedCategory">
						<option [ngValue]="null">Unassigned</option>
						<optgroup *ngFor="let g of categoryGroups" label="{{g.description}}">
							<option *ngFor="let item of g.categories" [ngValue]="item.id">{{item.description}}</option>
						</optgroup>
					</select>
				</ui-form-control>
				<ui-form-control>
					<label class="custom-control custom-checkbox">
						<input type="checkbox" class="custom-control-input" [(ngModel)]="addToToolkit">
						<span class="custom-control-label">Add to subsystem toolkit</span>
					</label>
				</ui-form-control>
			</ui-form>
			<hr>
		</div>

		<div class="d-flex" [ngClass]="{ 'invisible': !(mode === 'my-products' || catalogues?.length) }">
			<div class="input-group mb-2 flex-weight-1">
				<div class="input-group-prepend"><span class="input-group-text"><i class="wq wq-search"></i></span></div>
				<input *ngIf="!(mode === 'my-products' || catalogues?.length)" class="form-control" autocomplete="chrome-off"><!-- TODO: Placeholder to keep layout while invisible -->
				<input *ngIf="(mode === 'my-products' || catalogues?.length) && modal?.isOpen" type="search" class="form-control" placeholder="Search" [(ngModel)]="search" autocomplete="chrome-off" spellcheck="false" appDesktopFocus>
			</div>
			<div *ngIf="mode !== 'my-products' && catalogues?.length" class="input-group ml-3 mb-2 flex-weight-1">
				<div class="input-group-prepend"><span class="input-group-text">Catalogue</span></div>
				<select class="custom-select" [(ngModel)]="selectedCatalogue" (ngModelChange)="catalogueChange()">
					<option *ngFor="let c of catalogues" [ngValue]="c">{{c.name}}</option>
				</select>
			</div>
		</div>

		<ui-grid-row class="mb-4">
			<ui-grid-col class="mr-2" style="height: 500px;" *ngIf="data?.groups.length > 0">
				<!-- Categories -->
				<ui-card widget [wait]="data" [customHeaderDivider]="false">
					<div *ngIf="data" class="mt-n3 mb-2" style="height: 500px;">
						<ui-tree [data]="categoryTree"></ui-tree>
					</div>
				</ui-card>
			</ui-grid-col>

			<ui-grid-col weight-3 style="height: 500px;">
				<div class="d-flex flex-row border-bottom" *ngIf="data?.list && (mode === 'my-products' || selectedCatalogue)">
					<div class="d-flex flex-column flex-weight-1">
						<div class="d-flex">
							<div *ngFor="let t of tabList" class="px-3 py-4 flex-weight-1 hoverable align-items-center selectable" [ngClass]="{ 'border-primary border-4 text-bold border-bottom': activeTab === t.id, 'border-lightgrey': activeTab !== t.id , 'd-none': !t.show, 'd-flex': t.show}" (click)="tabChange(t)">
								<span class="text-uppercase text-small text-bold mr-2" [ngClass]="{ 'text-secondary': activeTab !== t.id }"><i class="mr-2" [ngClass]="t.icon"></i>{{t.title}}</span>
							</div>
						</div>
					</div>
				</div>

				<div class="mb-2 scrollable flex-weight-1" [appTutorial]="505">
					<table *ngIf="data?.list && (mode === 'my-products' || selectedCatalogue)" class="table table-hover vam">
						<thead>
							<tr *ngIf="app.orgInfo?.show_stock_level">
								<th colspan="2" class="sticky">Product</th>
								<th class="sticky text-center shrink nowrap">Stock Available</th>
								<th class="sticky text-center shrink nowrap">Stock On Order</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let item of data.list | keywords:search:['sku', 'manufacturer_name', 'manufacturer_sku', 'model', 'short_description'] | pagination:pagination" class="selectable" [ngClass]="{ 'table-success': isItemSelected(item) || isRecentlyAddedProduct(item.id) }" (click)="selectItem(item)" style="position: relative;">
								<td class="shrink pr-0">
									<div class="d-flex align-items-center justify-content-center">
										<span *ngIf="isRecentlyAddedProduct(item.id)" class="btn-success btn d-inline-block rounded-circle p-1 text-center mr-2" style="min-width: 1.5rem;">{{getAddedProducts(item.id).quantity}}</span>
										<div *ngIf="item.image_url" class="product-image mr-3" [style.backgroundImage]="'url(\'' + item.image_url + '\')' | safeStyle"></div>
									</div>
								</td>
								<td class="pl-0">
									<div *ngIf="item.short_description" class="text-bold">{{item.short_description}}</div>
									<div *ngIf="item.manufacturer_name || item.model" class="text-secondary">{{item.manufacturer_name}} {{item.manufacturer_name && item.model ? '/' : ''}} {{item.model}}</div>
									<div *ngIf="item.sku || item.manufacturer_sku" class="text-secondary text-tiny">{{item.sku || item.manufacturer_sku}}</div>
									<div *ngIf="item.special_order" class="text-warning"><i class="wq wq-warning"></i> Special order item</div>
								</td>
								<td *ngIf="app.orgInfo?.show_stock_level" class="text-center shrink nowrap">
									<span *ngIf="item.stock_level !== null" class="badge px-3" [ngClass]="(item.stock_level > 0) ? 'badge-success' : 'badge-danger'">{{item.stock_level}}</span>
								</td>
								<td *ngIf="app.orgInfo?.show_stock_level" class="text-center shrink nowrap">
									<span *ngIf="item.stock_ordered !== null" class="badge px-3" [ngClass]="(item.stock_ordered > 0) ? 'badge-success' : 'badge-danger'">{{item.stock_ordered}}</span>
								</td>
							</tr>
							<tr *ngIf="!pagination.count">
								<td [attr.colspan]="app.orgInfo?.show_stock_level ? 4 : 2" class="text-secondary">No products found.</td>
							</tr>
						</tbody>
					</table>

					<div *ngIf="!data?.list" class="w-100 h-100 d-flex align-items-center justify-content-center">
						<div class="spinner-border text-secondary"></div>
					</div>

					<div *ngIf="data?.list && (mode !== 'my-products' && !catalogues?.length)" class="w-100 h-100 d-flex align-items-center justify-content-center">
						<p class="text-secondary">You don't have any catalogues linked to your account.</p>
					</div>

					<div *ngIf="data?.list && (mode !== 'my-products' && catalogues?.length && selectedCatalogue === null)" class="w-100 h-100 d-flex align-items-center justify-content-center">
						<p class="text-secondary">Please select a catalogue.</p>
					</div>
				</div>
			</ui-grid-col>
		</ui-grid-row>

		<app-pagination [ngClass]="{ 'invisible': !(data?.list && (mode === 'my-products' || selectedCatalogue)) }" [pagination]="pagination"></app-pagination>
	</ng-container>

	<ng-container *ngIf="mode === 'selected'">
		<div class="mb-2 scrollable" style="height: 500px;">
			<table *ngIf="multiSelectList.length" class="table table-hover vam">
				<tbody>
					<tr *ngFor="let item of multiSelectList" class="selectable table-success" (click)="selectItem(item)">
						<td class="shrink pr-0">
							<div *ngIf="item.image_url" class="product-image mr-3" [style.backgroundImage]="'url(\'' + item.image_url + '\')' | safeStyle"></div>
						</td>
						<td class="pl-0">
							<div *ngIf="item.short_description" class="text-bold">{{item.short_description}}</div>
							<div *ngIf="item.manufacturer_name || item.model" class="text-secondary">{{item.manufacturer_name}} {{item.manufacturer_name && item.model ? '/' : ''}} {{item.model}}</div>
							<div *ngIf="item.sku || item.manufacturer_sku" class="text-secondary text-tiny">{{item.sku || item.manufacturer_sku}}</div>
						</td>
					</tr>
				</tbody>
			</table>
			<p *ngIf="!multiSelectList.length" class="text-secondary">No products selected.</p>
		</div>
	</ng-container>

	<button class="btn btn-primary" *ngIf="app.orgInfo.role.manage_products && mode === 'my-products'" (click)="openNewProductModal()" ui-modal-footer><i class="wq wq-add mr-2"></i>Add new product</button>
	<div class="flex-weight-1" ui-modal-footer></div>
	<button class="btn btn-secondary" (click)="modal.close()" ui-modal-footer>{{(fromQuoteEditor && multiSelectProduct) ? 'Close' : 'Cancel'}}</button>
	<button *ngIf="multiSelect" class="btn btn-success" (click)="addProductsButton()" [disabled]="!multiSelectList.length" ui-modal-footer><i class="wq wq-add mr-1"></i>Add Selected Products <ui-button-badge>{{multiSelectList.length}}</ui-button-badge></button>
</ui-modal>

<ng-template (close)="newProductModalClosed($event)" modalEditProduct></ng-template>
